.wrapper {
  min-height: 27em;
}

.splitter {
  width           : 100%;
  height          : .2em;
  display         : block;
  background-color: #f18000;
  border-bottom   : 1px solid white;
}

.navbar {
  background-color: #ffc800;
}

.mainSearch {
  height         : 3em;
  background     : url("../public/img/search-48.png") no-repeat .3em .3em;
  background-size: 2.3em;
  padding-left   : 3em !important;
}

.navbar .navbar-nav .nav-item .nav-link,
.custom-link {
  padding        : .5em 0;
  transition     : all 0.3s;
  font-size      : 1.5em;
  text-decoration: none;
  color          : rgba(0, 0, 0, .9);
}

.item-link {
  text-decoration: none;
  color          : rgba(0, 0, 0, .9);

}


.navbar .navbar-nav .nav-item .nav-link:hover,
.custom-link:hover {
  color: #f18000;
}

.footer {
  background-color: #ffc800;
}



.fm-informatica a {
  color          : #f18000;
  font-size      : 1.2em;
  padding        : .5em;
  border-radius  : .5em;
  text-decoration: none;
  transition     : all .3s;
}

.fm-informatica a:hover {
  color          : #333;
  text-decoration: none;
}

.flex-row {
  display       : flex;
  flex-direction: row;
  flex          : 1;
}

.row-hover:hover {
  background-color: #ffc800;
}

.p-info {
  color      : #f18000;
  font-weight: bold;
}

.row-hover:hover .p-info {
  color: white;
}


.qr-icon {
  color    : #333;
  cursor   : pointer;
  font-size: 1.5em;
}

.qr-icon:hover {
  color: #111;
}

.cart-icon {
  color    : #333;
  cursor   : pointer;
  font-size: 1.5em;
}

.cart-icon:hover {
  color: #111;
}

.fixed-icons {
  position        : fixed;
  z-index         : 99;
  transition      : all .3s;
  border-radius   : .5em;
  padding         : .5em 1em;
  background-color: black;
}

.fixed-icons-hidden {
  top    : 0;
  right  : 0;
  display: none;
}

.fixed-icons-block {
  top    : 3em;
  right  : 1em;
  display: block;
}

.fixed-icons svg {
  color     : whitesmoke;
  cursor    : pointer;
  font-size : 1.5em;
  transition: all .3s;
}

.fixed-icons svg:hover {
  color: #f18000;
}

.items-in-cart {
  text-decoration : none;
  color           : white;
  background-color: rgba(255, 0, 0, 0.95);
  padding         : 0.0em 0.5em;
  border-radius   : 1em;
  display         : inline-block;
  position        : absolute;
  font-size       : .8em;
  margin-left     : -0.6em;
  margin-top      : -0.6em;
}

.cart-add-remove {
  font-size: 2.2em;
  cursor   : pointer;
  color    : #393;
}

.cart-add-remove:hover {
  color: #f18000;
}

.price {
  font-size: 1.3em;
}

.quantity p {
  font-size : 2em;
  text-align: center;
  margin    : 0;
  padding   : 0;
  cursor    : pointer;
}

.quantity p:hover {
  color: #f18000;
}

.cart-edit:hover {
  color: #333;
}

.back-top {
  font-size : 2em;
  color     : #f18000;
  cursor    : pointer;
  transition: all .3s;
}

.back-top:hover {
  color: #333;
}

.enter-store-content {
  background         : url("../public/img/site-in.jpg");
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center;
}

.we-text {
  background         : url("../public/img/site-in.jpg");
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center;
  font-size          : 1.5em;
  color              : #000;
  text-shadow        : #fdfaef 1px 1px 10px;
}

.enter-store {
  display        : inline-block;
  color          : #f18000;
  cursor         : pointer;
  text-decoration: none;
  transition     : all 0.3s;
}

.enter-store:hover {
  color: #E05000;
}

.enter-store svg {
  font-size: 5em;
}

.enter-store p {
  font-size: 3em;
}

.pagination-page,
.pagination-page-current {
  cursor         : pointer;
  font-size      : 1.5em;
  text-decoration: none;
  transition     : all 0.3s;
}

.pagination-page {
  color: #f18000;
}

.pagination-page-current {
  color: black;
}

.pagination-page:hover {
  color: #E05000;
}

.whatsapp-link {
  color          : white;
  text-decoration: none;
}

.whatsapp-link:hover {
  color: whitesmoke;
}

.striped-1,
.striped-2 {
  border-bottom: 1px solid #DDD;
}

.striped-2 {
  background-color: #EEE;
}

.text-justify {
  text-align: justify;
}

.lb-small {
  font-size  : small;
  overflow   : hidden;
  white-space: nowrap;
  min-width  : 6em;
}

.btn {
  box-shadow: none !important
}

.img-item {
  max-height   : 10em;
  border-radius: .5em;
}

.noselection {
  -webkit-user-select: none;
  -moz-user-select   : none;
  -khtml-user-select : none;
  -ms-user-select    : none;
}

.row-gray {
  background-color: lightgray;
  padding         : 1.8em 1em;
}

.btn-success .discount {
  color       : lightgreen;
  padding-left: .2em;
}

.discount {
  color       : green;
  padding-left: .2em;
}