#carouselWe {
  padding: 0;
}

#carouselWe .carousel-item img {
  object-fit     : cover;
  object-position: center;
  overflow       : hidden;
  height         : 70vh;
}

#carouselWe .carousel-item:before {
  content: "";
  background-image:
    linear-gradient(to bottom,
      transparent, rgba(0, 0, 0, 0.5));
  display : block;
  position: absolute;
  top     : 0;
  width   : 100vw;
  height  : 70vh;
}

#carouselMain .carousel-item {
  max-height: 70vh;
}
#carouselMain .carousel-item img{
  max-height: 70vh;
}